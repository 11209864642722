<template>
  <div class="content flex-box vertical">
    <data-list
      ref="dataList"
      class="flex-grow"
      :url="url"
      :query="query"
      entity-name="固定资产清单"
      hide-show-detail
      hide-edit
      hide-delete
      show-row-num
      :action-width="240"
      :rules="rules"
      :columns="columns"
      :search-key-type="searchKeyType"
      :before-edit="beforeEdit"
      :before-update="beforeUpdate"
      :requestConfig="{ noTempleFilter: true }"
    >
      <template v-slot:button>
        <a-button type="primary" @click="exportData('tag')" :loading="tagExporting">
          {{ exporting ? "正在导出" : "导出标签" }}
        </a-button>
        <a-button type="primary" @click="exportData('excel')" :loading="exporting">
          {{ exporting ? "正在导出" : "导出EXCEL" }}
        </a-button>
        <upload-file uploadText="导入清单" type="primary" :url="`${url}/import`" @change="updateList"></upload-file>
        <a-button @click="downloadTemplate">下载模板</a-button>
      </template>

      <template v-slot:query="{ form }">
        <a-form-model-item label="保管人">
          <select-book type="user" v-model="form.custodian" @change="updateList"></select-book>
        </a-form-model-item>
        <a-form-model-item>
          <a-input v-model="form.goods_name" placeholder="物品名称/资产编码" allowClear></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-input v-model="form.spec" placeholder="规格型号" allowClear></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-select
            style="width: 202px;"
            placeholder="选择资产状态"
            :options="statusOptions"
            @change="updateList"
            v-model="form.status"
            allowClear
          >
          </a-select>
        </a-form-model-item>
        <!-- <a-form-model-item label="部门">
          <select-file type="department" v-model="form.dept_id" @change="updateList"></select-file>
        </a-form-model-item> -->
        <a-form-model-item>
          <a-select
            style="width: 202px;"
            placeholder="选择堂口"
            @change="updateList"
            :options="tkOptions"
            v-model="form.ketang_id"
            allowClear
            show-search
            option-filter-prop="children"
          >
          </a-select>
        </a-form-model-item>
      </template>

      <template v-slot:edit="{ form }">
        <a-form-model-item label="资产名称" prop="goods_id">
          <GoodsSelect v-model="form.goods_id" :form="form" :options="goodsOptions" />
        </a-form-model-item>
        <a-form-model-item label="资产编号">
          {{ form.asset_code }}
        </a-form-model-item>
        <a-form-model-item label="型号规格">
          <a-input v-model="form.spec" placeholder="输入型号规格" allowClear></a-input>
        </a-form-model-item>
        <a-form-model-item label="物品来源">
          <a-select placeholder="选择资物品来源" :options="fixedSourceOptions" v-model="form.source" allowClear>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="附件">
          <form-upload
            v-model="form.annex"
            listType="text"
            :size="50 * 1024 * 1024 * 1"
            customAccept=".excel,.word,.pdf"
            uploadTip="仅支持excel，word，pdf后缀的文件"
            save-file-name
          ></form-upload>
        </a-form-model-item>
        <a-form-model-item label="备注">
          <a-textarea v-model="form.notes" :rows="4" placeholder="填写备注" allowClear></a-textarea>
        </a-form-model-item>
      </template>

      <template v-slot:action="{ item }">
        <div class="row-btn" @mousedown.stop @mouseup.stop>
          <a class="txt-btn" @click.stop="showDetail(item)" v-action:query>查看</a>
          <a class="txt-btn" @click.stop="editRow(item)" v-action:update>编辑</a>
          <a class="txt-btn" @click.stop="exportTag(item)" v-action:update>标签</a>
          <a class="txt-btn" @click.stop="printTag(item)" v-action:update>标签打印</a>
          <a class="txt-btn danger" @click.stop="delRow(item)" v-action:delete>
            删除
          </a>
        </div>
      </template>
    </data-list>

    <!--    资产管理记录-->
    <a-modal
      :maskClosable="false"
      v-model="visible"
      :width="500"
      title="查看资产管理记录"
      ok-text="确认"
      cancel-text="取消"
      v-bind="[2, 3].includes(active) ? {} : { footer: null }"
      @ok="confirm"
      @cancel="cancel"
    >
      <a-tabs v-model="active">
        <a-tab-pane :key="1" tab="记录列表">
          <data-list
            v-if="asset"
            ref="recordList"
            class="record-list"
            url="/admin/asset-fixed-record"
            :query="recordQuery"
            entity-name="固定资产记录"
            hide-query
            hide-show-detail
            hide-edit
            hide-delete
            :columns="recordColumns"
            :action-width="100"
          >
            <template v-slot:action="{ item }">
              <div class="row-btn" @mousedown.stop @mouseup.stop>
                <a class="txt-btn" @click.stop="editRecordRow(item)" v-action:update>编辑</a>
                <a class="txt-btn danger" @click.stop="delRecordRow(item)" v-action:delete>
                  删除
                </a>
              </div>
            </template>
          </data-list>
        </a-tab-pane>
        <a-tab-pane :key="2" tab="修改记录" v-if="record.id" v-action:update>
          <FixedAssetRecordForm
            ref="editForm"
            :form="record"
            :tkOptions="tkOptions"
            class="record-form-box"
          ></FixedAssetRecordForm>
        </a-tab-pane>
        <a-tab-pane :key="3" tab="添加记录" v-action:add>
          <FixedAssetRecordForm
            ref="addForm"
            :form="record"
            :tkOptions="tkOptions"
            class="record-form-box"
          ></FixedAssetRecordForm>
        </a-tab-pane>
      </a-tabs>
    </a-modal>
    <div class="fixed-tag-modal" v-show="tagShow" @click="tagShow = false">
      <fixed-tag-print ref="tagPrint" :goods="goods" @loaded="handleLoaded" />
    </div>
  </div>
</template>

<script>
import { fixedStatusOptions, fixedSourceOptions } from "../../common/asset/type";
import {
    clone,
    dataConvertOptions,
    downLoadBlobFile,
    downLoadFile,
    getKeyTitle,
    getQuery,
    isEmpty,
    omit, printDom,
    stringConvertTimestamp,
    timestampConvertString,
} from "../../common/js/tool";
import { getTemple } from "../../common/js/storage";
import { fixedAssetTemplateUrl } from "../../common/asset/template";
import FixedAssetRecordForm from "./components/FixedAssetRecordForm.vue";
import GoodsSelect from "./components/GoodsSelect.vue";
import FixedTagPrint from "./components/FixedTagPrint.vue";

const exprotMap = {
  tag: {
    loadingKey: "tagExporting",
    exprotUrl: "/admin/asset-fixed/label-all-export",
    fileName: "标签数据",
  },
  excel: {
    loadingKey: "exporting",
    exprotUrl: "/admin/asset-fixed/export",
    fileName: "资产数据",
  },
};

export default {
  name: "FixedList",
  components: {
    FixedAssetRecordForm,
    GoodsSelect,
    FixedTagPrint,
  },
  data() {
    return {
      url: "/admin/asset-fixed",
      searchKeyType: {
        // dept_id: 2,
        ketang_id: 2,
        custodian: 2,
        goods_name: 2,
        spec: 2,
        status: 2,
      },
      columns: [
        { title: "资产编码", dataIndex: "asset_code" },
        {
          title: "资产名称",
          customRender: (text) => {
            return text?.goods?.name || "-";
          },
        },
        {
          title: "单位",
          customRender: (text) => {
            return text?.goods?.goodsUnit?.name || "-";
          },
        },
        {
          title: "当前位置",
          customRender: (text) => {
            let res = "-";
            const { keTang, position } = text;
            const ketang_name = keTang?.name || "";
            if (!isEmpty(ketang_name) && !isEmpty(position)) {
              res = `${ketang_name}-${position}`;
            } else if (!isEmpty(ketang_name)) {
              res = ketang_name;
            } else if (!isEmpty(position)) {
              res = position;
            }
            return res;
          },
        },
        {
          title: "保管人",
          dataIndex: "custodian",
          customRender: (text) => {
            return <open-data type="userName" openid={text} />;
          },
        },
        { title: "规格型号", dataIndex: "spec" },
        {
          title: "状态",
          dataIndex: "status",
          customRender: (text) => {
            let isValid = text != 3;
            return <a-tag color={isValid ? "#34bfa3" : "#f4516c"}>{isValid ? "有效" : "报废"}</a-tag>;
          },
        },
        {
          title: "更新日期",
          customRender: (text) => {
            const { fixedRecord } = text;
            return fixedRecord?.update_time?.split(" ")[0] || "-";
          },
        },
      ],
      rules: {
        goods_id: [{ required: true, message: "请选择资产", trigger: "change" }],
      },
      statusOptions: [{ label: "有效使用中", value: 4 }, ...fixedStatusOptions],
      fixedStatusOptions,
      fixedSourceOptions,
      tkOptions: [],
      /** 导出 */
      tagExporting: false,
      exporting: false,
      /** 资产记录 */
      asset: null,
      visible: false,
      active: 1,
      record: {},
      recordColumns: [
        {
          title: "类别",
          dataIndex: "category",
          customRender: (text) => {
            return <span>{getKeyTitle(fixedStatusOptions, text, "value", "label") || "-"}</span>;
          },
        },
        { title: "地点", dataIndex: "position" },
        {
          title: "保管人",
          dataIndex: "custodian",
          customRender: (text) => {
            return <open-data type="userName" openid={text} />;
          },
        },
        {
          title: "时间",
          dataIndex: "handover_date",
          customRender: (text) => {
            return timestampConvertString(text, "yyyy-MM-dd") || "-";
          },
          width: 110,
        },
      ],
      goods: null,
      goodsOptions: [],
      tagShow: false,
    };
  },
  computed: {
    query() {
      let temple = getTemple();
      return `&temple_id=${temple}&sort=-id&expand=goods.goodsUnit,keTang,fixedRecord`;
    },
    dataList() {
      return this.$refs.dataList;
    },
    action() {
      return this.$route.meta.action;
    },
    recordQuery() {
      let query = "&sort=-id";
      if (this.asset) {
        query += `&filter[fixed_id]=${this.asset.id}`;
      }
      return query;
    },
  },
  watch: {
    active(val) {
      if (val != 2) {
        this.record = {};
      }
    },
  },
  created() {
    this.getTk();
  },
  methods: {
    // 获取堂口列表
    getTk() {
      this.$axios({
        url: "/admin/ketang",
        method: "GET",
        params: {
          sort: "order,-id",
          pageSize: 9999,
        },
      }).then((res) => {
        this.tkOptions = dataConvertOptions(res.data);
      });
    },
    beforeEdit(form, fn) {
      if (form.goods_id && form.goods) {
        const goods = form.goods;
        const option = { ...goods, label: goods.name, value: goods.id };
        this.goodsOptions = [option];
      }
      if (form.annex) {
        form.annex = JSON.parse(form.annex);
      }
      omit(form, ["dept_id", "custodian", "operate_date"]);
      fn(form);
    },
    beforeUpdate(form, fn) {
      let res = clone(form);
      if (res.annex) {
        res.annex = JSON.stringify(res.annex);
      }
      fn(res);
    },
    getList() {
      this.dataList.getList();
    },
    updateList() {
      this.dataList.updateList();
    },
    getRecordList() {
      this.$refs.recordList.getList();
    },
    updateRecordList() {
      this.$refs.recordList.updateList();
    },
    // 查看
    showDetail(item) {
      this.asset = clone(item);
      this.active = 1;
      this.visible = true;
    },
    // 编辑固定资产
    editRow(item) {
      this.dataList.editRow(item);
    },
    // 删除固定资产
    delRow(item) {
      this.dataList.delRow(item);
    },
    dealRecordItem(item) {
      let record = clone(item);
      if (record.handover_date) {
        record.handover_date = timestampConvertString(record.handover_date, "yyyy-MM-dd");
      }
      // if (record.dept_id) {
      //   record.dept_id = [record.dept_id];
      // }
      return record;
    },
    // 编辑记录
    editRecordRow(item) {
      this.record = this.dealRecordItem(item);
      this.active = 2;
      this.$nextTick(() => {
        this.$refs.editForm.tangkouChange(this.record.ketang_id, true);
      });
    },
    // 删除记录
    delRecordRow(item) {
      this.$refs.recordList.delRow(item);
    },
    cancel() {
      this.asset = null;
      this.record = {};
      this.visible = false;
    },
    dealForm(record) {
      let form = clone(record);
      if (!form.fixed_id) {
        form.fixed_id = this.asset.id;
      }
      if (form.handover_date) {
        form.handover_date = stringConvertTimestamp(form.handover_date);
      }
      if (form.dept_id) {
        form.dept_id = form.dept_id[0];
      }
      return form;
    },
    confirm() {
      if ([2, 3].includes(this.active)) {
        let form = this.dealForm(this.record);
        let id = form.id;
        let formRef = this.$refs.addForm;
        if (id) {
          formRef = this.$refs.editForm;
        }
        formRef.validate().then(() => {
          let url = "/admin/asset-fixed-record";
          let method = "POST";
          let successMsg = "添加成功";
          if (id) {
            url += `/${id}`;
            method = "PATCH";
            successMsg = "更新成功";
          }
          this.$axios({
            url,
            method,
            data: form,
          }).then(() => {
            this.$message.success(successMsg);
            id ? this.getRecordList() : this.updateRecordList();
            this.active = 1;
            this.getList();
          });
        });
      }
    },
    // 下载模板
    downloadTemplate() {
      downLoadFile(fixedAssetTemplateUrl, "固定资产模板");
    },
    // 导出（标签和excel）
    exportData(type) {
      let { loadingKey, exprotUrl, fileName } = exprotMap[type];
      if (this[loadingKey]) return;
      this[loadingKey] = true;
      let temple = getTemple();
      let url = `${exprotUrl}?temple_id=${temple}` + getQuery.call(this.$refs.dataList, this.searchKeyType);
      this.$axios(url, { noTempleFilter: true, responseType: "blob" })
        .then((res) => {
          downLoadBlobFile(res, fileName);
        })
        .finally(() => {
          this[loadingKey] = false;
        });
    },
    // 导出单个标签
    exportTag(item) {
      let temple = getTemple();
      let url = `/admin/asset-fixed/label-export?id=${item.id}&temple_id=${temple}`;
      this.$axios(url, { noTempleFilter: true, responseType: "blob" }).then((res) => {
        downLoadBlobFile(res, "标签数据");
      });
    },
    printTag(item) {
      this.goods = item;
    },
    handleLoaded() {
      // this.tagShow = true;
      // if(this.tagShow) return;
      this.$nextTick(() => {
        const style = document.createElement('style');
        style.setAttribute("type", 'text/css');
        style.setAttribute("meta", "print");
        const css = '@page { margin: 0; padding: 0; border: 0; }';
        style.appendChild(document.createTextNode(css));
        printDom(this.$refs.tagPrint.$el, style);
      })
    }
  },
};
</script>

<style lang="less" scoped>
.record-list {
  min-height: 500px;
}
.record-form-box {
  padding: 0 32px;
}
.fixed-tag-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: @black;
  z-index: 100;
  overflow: auto;
}
</style>
