<template>
  <a-form-model
    class="item-form"
    ref="editForm"
    :model="form"
    :rules="rules"
    :labelCol="modalForm.labelCol"
    :wrapperCol="modalForm.wrapperCol"
    :layout="modalForm.layout"
    :labelAlign="modalForm.labelAlign"
  >
    <a-form-model-item label="类别" prop="category">
      <a-select placeholder="选择类别" :options="fixedStatusOptions" v-model="form.category" allowClear></a-select>
    </a-form-model-item>
    <!-- <a-form-model-item label="部门" prop="dept_id">
      <select-file v-model="form.dept_id" @change="deptChange"></select-file>
    </a-form-model-item> -->
    <a-form-model-item label="堂口名称" prop="ketang_id">
      <a-select
        placeholder="选择堂口"
        :options="tkOptions"
        v-model="form.ketang_id"
        allowClear
        show-search
        option-filter-prop="children"
        @change="tangkouChange($event)"
      >
      </a-select>
    </a-form-model-item>
    <a-form-model-item label="所在位置">
      <a-input v-model="form.position" placeholder="请输入所在位置" allowClear></a-input>
    </a-form-model-item>
    <a-form-model-item label="保管人" prop="custodian" :extra="userList.length > 0 ? '' : '该堂口暂未设置主管'">
      <a-select placeholder="选择保管人" v-model="form.custodian" allowClear>
        <a-select-option :value="user" v-for="user in userList" :key="user">
          <open-data type="userName" :openid="user" />
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item label="交接日期" prop="handover_date">
      <a-date-picker
        v-model="form.handover_date"
        format="YYYY-MM-DD"
        value-format="YYYY-MM-DD"
        placeholder="选择日期"
        style="width: 100%;"
        :allow-clear="false"
        :disabled-date="disabledDate"
      />
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import moment from "moment";
import { fixedStatusOptions } from "../../../common/asset/type";

export default {
  name: "FixedAssetRecordForm",
  props: {
    form: Object,
    tkOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      modalForm: { layout: "vertical", labelAlign: "left" },
      rules: {
        category: [{ required: true, message: "请选择类别", trigger: "change" }],
        // dept_id: [{ required: true, message: "请选择部门", trigger: "change" }],
        ketang_id: [{ required: true, message: "请选择堂口", trigger: "change" }],
        custodian: [{ required: true, message: "请选择保管人", trigger: "change" }],
        handover_date: [{ required: true, message: "请选择交接日期", trigger: "change" }],
      },
      fixedStatusOptions,
      userList: [],
    };
  },
  methods: {
    disabledDate(current) {
      return current && current > moment().endOf("day");
    },
    // 堂口改变 (flag: 标识手动触发)
    tangkouChange(val, flag) {
      this.userList = [];
      if (!flag) {
        this.$set(this.form, "custodian", undefined);
      }
      if (val) {
        let target = this.tkOptions.find((item) => item.value === val);
        this.userList = target.admin_user_ids?.split(",") || [];
      }
    },
    getUser(val) {
      this.$axios({
        url: "/admin/ketang",
        method: "GET",
        params: {
          sort: "-id",
          pageSize: 9999,
          "filter[dept_id]": val[0],
        },
      }).then((res) => {
        let userList = [];
        res.data?.forEach((item) => {
          let ids = item.admin_user_ids;
          if (ids) {
            ids = ids.split(",");
            userList = userList.concat(ids);
          }
        });
        // 去重
        this.userList = Array.from(new Set(userList));
      });
    },
    deptChange(val) {
      this.userList = [];
      if (val && val.length > 0) {
        this.getUser(val);
      } else {
        this.form.custodian = undefined;
      }
    },
    getCustodianExtra() {
      if (!this.form.dept_id || this.form.dept_id.length == 0) {
        return;
      }
      return this.userList.length > 0 ? "" : "该堂口暂未设置主管";
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.editForm.validate((flag) => {
          if (flag) {
            resolve();
          } else {
            reject();
          }
        });
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
