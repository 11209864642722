<template>
  <div :style="boxStyle">
    <div :style="titleStyle">杭州灵隐寺固定资产登记卡</div>
    <div :style="detailStyle">
      <div :style="infoStyle">
        <div :style="{ ...infoItemStyle, ...(i > 0 ? {borderTop: '1px solid #000'} : {})}" v-for="(item, i) in infoList" :key="i">
          <div :style="infoLabelStyle">{{ item.label }}</div>
          <div :style="infoTextStyle">{{ item.value }}</div>
        </div>
      </div>
      <div :style="qrBoxStyle">
        <div :style="qrStyle">
          <vue-qr
            :text="goods.asset_code"
            :margin="0"
            :size="258"
            :callback="callback"
            v-if="goods"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import VueQr from "vue-qr";
    export default {
        name: "FixedTagPrint",
        props: {
            goods: Object
        },
        components: { VueQr },
        data() {
            return {
                boxStyle: {
                    display: "flex",
                    flexDirection: "column",
                    width: "711px",
                    height: "450px",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                    border: "1px solid #000",
                    color: "#000"
                },
                titleStyle: {
                    height: "125px",
                    fontSize: "44px",
                    lineHeight: "125px",
                    textAlign: "center",
                    fontWeight: "bold"
                },
                detailStyle: {
                    flex: 1,
                    display: "flex",
                    borderTop: "1px solid #000",
                },
                infoStyle: {
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    fontSize: "36px",
                    fontWeight: "bold"
                },
                infoItemStyle: {
                    flex: 1,
                    display: "flex",
                    alignItem: "center",
                },
                infoLabelStyle: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "108px",
                },
                infoTextStyle: {
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    padding: "0 20px",
                    borderLeft: "1px solid #000",
                },
                qrBoxStyle: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "281px",
                    borderLeft: "1px solid #000",
                },
                qrStyle: {
                    width: "258px",
                    height: "258px",
                }
            }
        },
        computed: {
            infoList() {
                const res = [
                    { label: "名称", value: ""},
                    { label: "编号", value: ""},
                    { label: "堂口", value: ""},
                ]
                const goods = this.goods;
                if (goods) {
                    res[0].value = goods.goods ? goods.goods.name.replace(/\(.*?\)/g, "") : "";
                    res[1].value = goods.asset_code || "";
                    res[2].value = goods.keTang ? goods.keTang.name : goods.position ? goods.position.split("-")[0] : "";
                }
                return res;
            }
        },
        methods: {
            callback() {
                this.$emit("loaded");
            }
        }
    }
</script>

<style lang="less">

</style>
